/* eslint-disable camelcase */

// from: https://github.com/gaiaresources/abis-mapping/blob/main/abis_mapping/templates/occurrence_data/metadata.json

import { MaybeRef } from 'vue'

export const CANNOT_FIND_TEMPLATE_VERSION_ERROR_MESSAGE =
  'Cannot find the version of the template from the file name. ' +
  'Please add the version of the template you are using in the file name. ' +
  'For example: my_data-v1.0.0.csv'

export const templateTypeValues = [
  'incidental_occurrence_data',
  'survey_metadata',
  'survey_site_data',
  'survey_site_visit_data',
  'survey_occurrence_data'
] as const

export type TemplateTypeValues = (typeof templateTypeValues)[number]

export enum TemplateSamplingType {
  incidental = 'incidental',
  systematic_survey = 'systematic survey'
}

export interface Template {
  id: string
  name: TemplateTypeValues
  label?: string
  version: string
  description?: string
  biodiversity_type?: string
  spatial_type?: string
  file_type?: string
  sampling_type: string
  instructions_url?: string
  schema_url?: string
  template_url?: string
  download_url?: string
  template_lifecycle_status?: string
}

/**
 * The result of the template loader
 *
 * The server returns names in snake_case, so we need to convert them to camelCase so eslint doesn't cry
 */
export type TemplateLoaderResult = {
  incidentalOccurrenceData: Template[]
  surveyMetadata: Template[]
  surveySiteData: Template[]
  surveySiteVisitData: Template[]
  surveyOccurrenceData: Template[]
  incidentalOccurrenceDataLatest: Template
  surveyMetadataLatest: Template
  surveySiteDataLatest: Template
  surveySiteVisitDataLatest: Template
  surveyOccurrenceDataLatest: Template
}

export const SUPPORTED_FORMATS = ['csv', 'text/csv']

export const MAX_PREVIEW_LEN = 30

export interface GenericFormFields {
  // provider details
  name: null | string
  email: null | string
  organisation: null | string
  organisation_url: null | string
  organisation_is_creator_and_rights_holder: null | string // yes/no
  organisation_data_rights_holder: null | string
  organisation_data_rights_holder_url: null | string
  organisation_dataset_creator: null | string
  organisation_dataset_creator_url: null | string

  // dataset information
  title: null | string
  license: null | string
  dataset_identifier: null | string
  dataset_description: null | string

  // data restrictions
  dataset_need_to_be_restricted_from_public_view: null | string // yes/nop
  reasons_for_embargo: Array<string>
  embargo_end_date: null | string | Date
  agree_generalised_records: boolean

  // provider details
  sharing_agreement: boolean
}

export interface IncidentalCsvFields {
  template_id: null | string
  file: null | File
}

export interface SurveyCsvFields {
  dataset_has_site_data: null | string
  file_metadata: null | File
  file_occurrence_data: null | File
  file_site_data: null | File
}

export interface IncidentalFormFields extends GenericFormFields, IncidentalCsvFields {}

export interface SurveyFormFields extends GenericFormFields, SurveyCsvFields {}

// Options as array (for iteration)
export const organisationIsCreatorAndRightsHolderOpts = ['Yes', 'No'] as const

// Options as type (for validation)
export type organisationIsCreatorAndRightsHolderOptsType = (typeof organisationIsCreatorAndRightsHolderOpts)[number]

export const datasetNeedToBeRestrictedFromPublicViewOpts = ['No', 'Yes'] as const

export type datasetNeedToBeRestrictedFromPublicViewOptsType =
  (typeof datasetNeedToBeRestrictedFromPublicViewOpts)[number]

export const datasetHasSiteDataOpts = ['No', 'Yes'] as const
export const datasetHasSiteVisitDataOpts = ['No', 'Yes'] as const

export type datasetHasSiteDataOptsType = (typeof datasetHasSiteDataOpts)[number]
export type datasetHasSiteVisitDataOptsType = (typeof datasetHasSiteVisitDataOpts)[number]

export const licenseOpts = [
  {
    value: 'CC0',
    link: 'https://creativecommons.org/publicdomain/zero/1.0/'
  },
  {
    value: 'CC BY 4.0',
    link: 'https://creativecommons.org/licenses/by/4.0/'
  }
]

export interface SecureUploadForm {
  email: MaybeRef<string | null>
}

export interface SubmissionSummary {
  id?: string
  state?: string
  submitted_at?: string
  updated_at?: string
  error?: string
}

export const reasonsForEmbargoOpts = [
  'The data are currently unpublished and I wish to embargo until publication',
  'I wish to embargo the data for legal reasons',
  'I wish to embargo the data for financial reasons'
]

// Frictionless CSV validation report (https://framework.frictionlessdata.io/docs/guides/validating-data.html)
export interface validationErrorReport {
  type: string
  title: string
  description: string
  message: string
  tags: string[]
  note: string
  rowNumbers: number[]
  label: string
  fieldName: string
  fieldNumber: number
}
export interface validationTaskReport {
  name?: string
  type?: string
  valid?: boolean
  labels?: string[]
  stats?: object
  warnings: object[]
  errors: validationErrorReport[]
  // + other stuff we don't need
}
export interface validationReport {
  valid: boolean
  stats: object
  warnings: object[]
  errors: object[]
  tasks: validationTaskReport[]
}

// response from the template/validate/ssd-templates
// it can be a CSV validation report
export interface validationSSDTemplateReport {
  metadata_report: validationReport
  occurrence_data_report: validationReport
  site_data_report: validationReport
  site_visit_data_report: validationReport
}
// or a general error returned by backend. Example: {"error":"Invalid combination of template IDs supplied"}
export interface genericError {
  error: string
}
// helpers to distinguish the type of returned response.
export function isSSDValidationReport(response: any): boolean {
  // A SSD validation report should have at least an occurrence_data_report property.
  return response && response instanceof Object && 'occurrence_data_report' in response
}
export function isGenericError(response: any): boolean {
  return response && response instanceof Object && 'error' in response
}
